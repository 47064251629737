'use strict';
var app = angular.module('FSS');


// COMPONENT
app.component('loginComponent', {
    templateUrl: '/common/login/login-modal-component.html',
    bindings: { resolve: '<',   close: '&', dismiss: '&'},
    controller: [ 'AuthService','$window', '$rootScope', '$scope', '$state',  function ( AuthService, $window, $rootScope, $scope, $state) {
        var $ctrl = this;


        $ctrl.googleLoginStarted = false;
        $ctrl.loginInProgress = false;
        $ctrl.loginSucceeded = false;

        $ctrl.$onInit = function () {

            // logout existing session if any on init
            AuthService.ClearCredentials();

            // define some items  vars for component
            $ctrl.redirectToBase = $ctrl.resolve.redirectToBase;
            $ctrl.redirectToUrl = $ctrl.resolve.redirectToUrl; // passed data to component, areaProp, item etc..
            if($ctrl.resolve.loginUsername) {
                $ctrl.login_username = $ctrl.resolve.loginUsername;
            }
        };
        /**
         * NEW GOOGLE LOGIN METHOD USING API V2
         */

        AuthService.loadGapi().then(() => {
            $ctrl.__loginGoogle = function(){
                $ctrl.googleLoginStarted = true;
                $ctrl.googleError = "";
                AuthService.signIn().then((response) => {
                    var accessToken = response.getAuthResponse().access_token;
                    $ctrl.googleLoginStarted = false;
                    AuthService.__login(null, null, accessToken, $state.params.url, (err, response) => {
                        if (!err){
                            $ctrl.loginSucceeded = true;
                            $ctrl.close();
                        } else {
                            $ctrl.loginSucceeded = false;
                            $ctrl.googleLoginStarted = false;
                            $ctrl.googleError = (err && err.data ? err.data.error : "Error while login")
                            $scope.$apply();
                            return;
                        }
                    });
                })
                .catch((err) => {
                    $ctrl.googleLoginStarted = false;
                    $ctrl.googleError = err;
                    // $scope.$apply();
                })
            }
            $ctrl.logout = () => {
                AuthService.signOut().then(() =>{
                    $ctrl.loginSucceeded = false;
                    AuthService.ClearCredentials();
                    this.login_username = "";
                    this.login_password = "";
                    // $location.url('/q/home');
                })
                .catch(err => console.log("error on logout:", err))
            }
        })

        /**
         * NEW LOCAL LOGIN METHOD USING API V2
         */
        $ctrl.__login = () => {
            $ctrl.dataLoading = true;
            $ctrl.loginInProgress = false;
            $ctrl.loginSucceeded = false;
            AuthService.__login($ctrl.login_username, $ctrl.login_password, null, $state.params.url, (err, response) => {
                $ctrl.dataLoading = false;
                $ctrl.loginInProgress = false;
                if (err){
                    $ctrl.loginSucceeded = false;
                    $ctrl.googleError = (err && err.data ? err.data.error : "Error while login")
                    $scope.$apply();
                    return;
                }
                if(response && response.currentUser && response.currentUser.baseUrl) {
                    $ctrl.loginSucceeded = true;
                    $ctrl.close();
                }
            });
        }
  }]
});

'use strict';
var app = angular.module('FSS');

app.component('icon', {
    template: `
        <div ng-bind-html="$ctrl.markup"></div>
    `,
    bindings: { name: '@', setSvgClass: '@' },
    controller: ['SVGICONS', '$sce', function (SVGICONS, $sce) {
        var $ctrl = this;

        $ctrl.addClass = (svgElement) => {
            if(svgElement && !$ctrl.setSvgClass) {
                return svgElement;
            }
            if(svgElement && $ctrl.setSvgClass) {
                var position = 4;
                var classAttr = ' class="'+$ctrl.setSvgClass+'" ';
                var output = [svgElement.slice(0, position), classAttr, svgElement.slice(position)].join('');
                return output;
            }
            return;
        }

        $ctrl.$onInit = function () {
            if($ctrl.setSvgClass) {
                $ctrl.markup = $sce.trustAsHtml( $ctrl.addClass(SVGICONS[$ctrl.name]) );
            } else {
                $ctrl.markup = $sce.trustAsHtml(SVGICONS[ $ctrl.name]);
            }
        };
        $ctrl.$onChanges = function () {
            if ($ctrl.setSvgClass) {
                $ctrl.markup = $sce.trustAsHtml($ctrl.addClass(SVGICONS[$ctrl.name]));
            } else {
                $ctrl.markup = $sce.trustAsHtml(SVGICONS[$ctrl.name]);
            }
        };

    }]
});
